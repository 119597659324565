.internal_content {

	&.community_and_courses {
		padding: 25px 35px;
	}
}

.community_and_courses_inner {

	max-width: 590px;
	width: 100%;
	margin: 0 auto;

	h3 {
		font-family: $font_secondary;
		color: $primary;
		font-size: 30px;
		font-weight: 700;
		border-bottom: 3px solid $gray;
		padding-bottom: 20px;
		text-align: center;
		max-width: 565px;
		width: 100%;
		margin: 0 auto 25px;
	}

	p {
		line-height: 1.4;
		margin-bottom: 20px;
	}

	.c_and_c_bold {
		display: block;
		color: $primary;
		font-family: $font_secondary;
		font-size: 18px;
		font-weight: 700;
		line-height: 1.4;
		margin-bottom: 20px;

		&.no_margin {
			margin: 0;
		}
	}

	.c_and_c_workshops {
		margin: 20px 0 60px 40px;
	}
}

#community_and_courses_list {

	margin-bottom: 40px;

	li {
		margin: 0 0 10px 40px;
		line-height: 1.4;
		position: relative;

		&:after {
			position: absolute;
			content: '';
			background: $accent;
			width: 10px;
			height: 10px;
			top: 6px;
			left: -40px;
			transform: rotate(45deg);
		}
	}
}

.c_and_c_course_wrapper {
	max-width: 590px;
	width: 100%;
	margin: 0 auto;
}

.c_and_c_course_inner {
	border: 2px solid #e6e6e6;
	border-top-left-radius: 45px;
	border-bottom-right-radius: 45px;
	background: #fff;
	box-shadow: 18px 18px 0 -6px rgba(#000, .10);
	padding: 75px 35px 50px;
	margin-bottom: 75px;
	text-align: center;

	p {
		font-size: 25px;
		font-weight: 700;
		font-family: $font_secondary;
		color: $primary;
	}
}

@media screen and (max-width: 750px) {

	.community_and_courses_inner {

		h3 {
			font-size: 24px;
			margin: 0 auto 15px;
		}
	}
}

@media screen and (max-width: 500px) {

	.community_and_courses_inner {

		.c_and_c_workshops {
			margin: 0 0 40px;
		}
	}

	.c_and_c_course_inner {
		padding: 30px 20px;
		margin-bottom: 50px;

		p {
			font-size: 22px;
		}
	}
}