#internal_header {
	background: url('/dist/images/backgrounds/img_whoweserve.jpg');
	background-repeat: no-repeat;
	object-fit: cover;
}

#who_we_serve {

	.internal_content {
		padding: 45px 0;
	}

	.who_we_serve {
		text-align: center;
		font-size: 1.6rem;
		color: $primary;
		font-weight: 600;
		line-height: 1.2;

		p {
			padding: 0 20px;
			font-family: $font_secondary;
		}

		span {
			color: $secondary;
			font-family: $font_secondary;
			font-size: 1.4rem;

			&.error, &.success {
				font-family: $font_primary;
				font-size: 16px;
			}
		}

		.video_placeholder {
			background: url('/dist/images/content/img_tree_bg.jpg');
			background-position: center center;
			height: 350px;
			margin-top: 30px;
			color: #fff;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-direction: column;

			p {
				font-weight: 200;
				font-size: 1.5rem;
				padding: 10px 0;
				margin-top: 15px;
			}

			hr {
				width: 375px;
				height: 2px;
				margin: 0 auto;
				background-color: $accent;
				margin-bottom: 40px;
			}
		}

		.info_content {
			margin: 60px 80px;
			font-size: 1rem;
			font-weight: 500;
			text-align: left;

			.info_banner {
				max-width: 100%;
				color: #fff;
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
				object-fit: contain;
				display: flex;
				align-items: center;
				padding: 50px 65px;

				p {
					padding-left: 42px;
					font-size: 2rem;
					text-shadow: 5px 5px 8px $dark_gray;
				}

				&.healthcare {
					background-image: url("/dist/images/content/img_healthcare_professionals.jpg");
				}

				&.work {
					background-image: url("/dist/images/content/img_work_life_balace.jpg");
				}

				&.career {
					background-image: url("/dist/images/content/img_career_professionals.jpg");
				}

				&.teachers {
					background-image: url("/dist/images/content/img_teahers.jpg");
				}

				&.therapists {
					background-image: url("/dist/images/content/img_professional_therapists.jpg");
				}
			}

			.info_list {
				padding: 60px 125px;
				background: linear-gradient(180deg, #f2f2f2 0%, #ffffff 100%);

				ul {
					list-style: none;
					padding: 20px 10px;

					li {
						margin: 0;
						margin-bottom: 20px;
						padding-left: 2em;
						position: relative;
						color: $dark_gray;
						font-size: 14px;
						font-weight: 600;
						line-height: 1.4;

						&:after {
							content: '';
							height: .8em;
							width: .8em;
							background: $accent;
							display: block;
							position: absolute;
							transform: rotate(45deg);
							top: .25em;
							left: 0;
						}
					}
				}

				.btn {
					display: block;
					margin: 0 auto;
					width: 250px;
					padding: 20px 50px 20px 30px;
					text-align: center;
				}
			}
		}

		.disclaimer {
			margin: 0 80px;
			padding: 2em 2em;
			background: linear-gradient(180deg, #ffffff 0%, #f2f2f2 100%);
			color: #767676;
			text-align: left;
			font-size: 1.1rem;
			font-weight: 550;

			p {
				font-size: 1rem;
			}
		}
	}
}

/***>>>Media Queries***/

@media screen and (max-width: 1040px) {

	#who_we_serve {

		.who_we_serve {

			.info_content {
				margin: 60px 0;

				.info_banner {
					padding: 40px 20px;
				}

				.info_list {
					padding: 40px 50px 0 50px;

					.btn {
						width: 200px;
					}
				}
			}

			.disclaimer {
				margin: 0;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#who_we_serve {

		.who_we_serve {

			.info_content {
				margin: 40px 0;

				.info_banner {
					padding: 20px 20px;
				}

				.info_list {
					padding: 40px 30px 0 30px;

					.btn {
						width: 200px;
					}
				}
			}
		}
	}
}