.home_container {
	display: flex;
	flex-direction: column;
	text-align: center;

	.btn {
		border: 1px solid #fff;
	}

	h2 {
		font-family: $font_heading;
		font-size: 3.6rem;
		word-spacing: 4px;
	}

	.home_banner {
		background-color: $primary;

		.home_image {
			background: url('/dist/images/backgrounds/img_homepage_hero.jpg');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			height: 50vh;
			min-height: 400px;
			display: flex;
			justify-content: center;
			flex-direction: column;

			h2 {
				color: $smoke;
				padding: 45px 10px;
			}

			.btn_ctn {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;

				a {
					font-size: 1.5rem;
					max-width: 280px;
					width: 100%;
					padding: 20px 40px;
					margin: 10px;
				}
			}
		}

		.features {
			display: flex;
			gap: 20px;
			flex-wrap: wrap;
			justify-content: center;
			color: $primary;

			.feature_item {
				width: 290px;
				display: flex;
				align-items: center;
				margin: -50px 0 40px 0;
				background-color: $smoke;
				font-size: 1.2rem;
				border-radius: 40px 0 40px 0;
				box-shadow: 2px 2px 8px #1a1f2f;

				img {
					padding: 20px 0 20px 20px;
				}

				p {
					line-height: 1.2;
					padding: 20px 20px;
				}
			}
		}
	}

	.sessions {
		text-align: center;
		padding: 4em 3em;
		color: $primary;

		h2 {
			grid-column: 1 / -1;
			margin-bottom: .9em;
		}

		.session_info {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.session_content {
				max-width: 500px;
				border: 1px solid #d5cfcf;
				padding: 5.5em 3.7em;
				border-radius: 50px 0;
				border-collapse: collapse;
				box-shadow: 15px 15px 1px -4px $accent;

				img {
					margin-bottom: 3.5em;
				}

				p {
					color: $dark_gray;
					padding-inline: 1.7em;
					text-align: start;
					line-height: 1.5;
				}
			}

			.session_features {
				max-width: 500px;
				margin-left: 4em;

				hr {
					margin: 0;
				}

				h3,img {
					font-size: 1.2rem;
					font-weight: 900;
					padding-block: 1em;
				}

				.item_wrap {
					text-align: left;
					padding-left: 5em;
					display: grid;
					grid-template-columns: 1fr;

					.item_title {
						display: flex;
						justify-content: start;
						cursor: pointer;
						position: relative;
						align-items: center;

						&:after {
							content: "";
							position: absolute;
							top: 45%;
							right: 15px;
							width: 15px;
							height: 15px;
							border-right: 4px $accent solid;
							border-bottom: 4px $accent solid;
							transform: translateY(-20%) rotate(-45deg);
							transition: border-right ease .35s, border-bottom ease .35s, transform ease .35s;
						}

						.title {
							padding-inline: 2em;
							font-size: 1.5rem;
							top: 10%;
						}
					}

					.item_text_wrap {
						line-height: 1.5;
						grid-column: 1 / -1;
						color: $dark_gray;
						max-height: 0;
						overflow: hidden;
					}

					&.active {
						margin-bottom: 10px;

						.item_title {

							&:after {
								border-right: 4px $primary solid;
								border-bottom: 4px $primary solid;
								transform: translateY(-70%) rotate(45deg);
							}
						}

						.item_text_wrap {
							max-height: 450px;
						}
					}
				}
			}
		}
	}

	.approach {
		background: url('/dist/images/backgrounds/img_homepage_coching.jpg');
		background-repeat: no-repeat;
		background-position: center right;
		background-size: cover;
		display: flex;
		justify-content: center;
		padding: 6.5em 1em;
		margin-top: 45px;

		.approach_container {
			background-color: #fff;
			border-radius: 35px;
			width: max(970px, 400px);
			line-height: 1.5;
			padding-block: 2.5em;
			box-shadow: 3px 3px 15px $dark_gray;

			h2,h4 {
				color: $primary;
				padding-inline: 20px;
			}

			h4 {
				font-weight: 900;
				font-size: 1.2rem;
			}

			p,h4 {
				margin-bottom: 22px;
			}

			.approach_info {
				text-align: left;
				padding: 2em 7.5em 1em 7.5em;
			}
		}
	}

	.about_us {
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.about_banner {
			background: url('/dist/images/backgrounds/img_homepage_tree.jpg');
			background-size: cover;
			background-position: top center;
			background-repeat: no-repeat;
			padding-block: 5em;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			h2 {
				margin-bottom: 30px;
			}

			p {
				max-width: 900px;
				padding-inline: 20px;
				text-align: start;
				line-height: 1.5;
				margin-bottom: 17em;
			}
		}

		.employees {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			width: 100%;
			background: linear-gradient(90deg, #fff 0%, $smoke 50%, #fff 100%);

			.owner_about {
				border-radius: 40px 0 40px 0;
				box-shadow: 2px 2px 15px #7d7979, 10px 10px 1px $accent;
				padding: 3em;
				max-width: 1000px;
				background: #fff;
				display: grid;
				grid-template-columns: .3fr 1fr;
				grid-template-rows: 2fr;
				align-items: center;
				justify-content: center;
				margin: 2em 1em;
				text-align: start;

				img {
					margin: 1em;
				}

				h3 {
					font-size: 2rem;
					color: $primary;
				}

				p {
					color: $dark_gray;
					line-height: 1.5;
					padding: 2em 0;
				}

				&:first-child {
					margin-top: -16em;
				}
			}
		}
	}

	.session_plans {
		padding: 5em 3em;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		h2 {
			color: $primary;
			text-decoration: underline;
			text-decoration-color: $smoke;
			text-decoration-thickness: 3px;
			text-underline-offset: 20px;
			margin-bottom: 35px;
		}

		.sub_title {
			color: #626060;
			font-size: 1.5rem;
			position: relative;
			text-decoration: none;

			&::after {
				content: '';
				height: .5em;
				width: .5em;
				background: $accent;
				display: block;
				position: absolute;
				transform: rotate(45deg);
				top: 5px;
				right: -35px;
			}

			&::before {
				content: '';
				height: .5em;
				width: .5em;
				background: $accent;
				display: block;
				position: absolute;
				transform: rotate(45deg);
				top: 5px;
				left: -35px;
			}

			&:hover {
				text-decoration: underline;
				text-underline-offset: 5px;
			}
		}

		.plan_items {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: 2em;
			flex-wrap: wrap;
			padding: 6em 4em 4em 4em;

			.plan {
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				h3 {
					background: linear-gradient(360deg, #fff 0%, $smoke 100%);
					color: $primary;
					font-size: 1.8rem;
					font-weight: 600;
					padding: 20px;
				}

				.plan_image {
					position: relative;

					img {
						background-repeat: no-repeat;
					}

					a {
						width: 265px;
						padding: .8em 1.2em;
						position: absolute;
						top: 73%;
						left: 20%;
						font-size: 1.3rem;
						font-weight: 600;
					}
				}
			}
		}
	}

	.worked_with {
		background-color: $primary;
		padding-block: 6em;

		h2 {
			color: $smoke;
			text-decoration: underline;
			text-decoration-color: $accent;
			text-decoration-thickness: 3px;
			text-underline-offset: 20px;
			margin-bottom: 1.2em;
			line-height: 1.4;
		}

		.companies {
			display: grid;
			grid-template-rows: repeat(2, 1fr);
			grid-template-columns: repeat(auto-fill, 360px);
			grid-gap: 1em;
			justify-content: center;
			align-items: center;
			padding-inline: 15em;

			.company_image {
				display: inline-block;

				img {
					border-radius: 20px;
					max-width: 360px;
				}
			}
		}
	}
}

#reviews_caro {
	padding: 3em 0;
	background: url("/dist/images/backgrounds/img_homepage_testimonials.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.reviews_wrapper {
		max-width: 1025px;
		width: 100%;
		padding-block: 10em;
		margin-inline: 1em;

		.carousel_inner {
			border-radius: 40px 0 40px 0;
			background-color: #fff;
			max-width: 1025px;
			padding: 4em;
			display: flex;
			flex-direction: column;
			margin: 0 auto;

			.title_container {
				padding-inline: 3em;
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				margin-bottom: 2em;

				h3 {
					flex: 1 auto;
					font-size: 3.5rem;
					color: $primary;
					font-family: $font_heading;
				}

				.left {
					transform: rotate(180deg);
				}
			}

			.reviews_container {
				display: flex;
				align-items: center;
				text-align: left;
				padding-inline: 3em;

				.ai_caro_instance {

					.caro_inner {
						overflow: hidden;

						.review_container {
							line-height: 1.2;
						}
					}
				}

				.control {
					cursor: pointer;
					padding-inline: 1em;

					&.left {
						transform: rotate(180deg);
					}
				}
			}
		}
	}
}

#reviews_wrapper {
	flex: 1;
}

/***>>Media Queries***/

@media screen and (max-width: 1700px) {

	.home_container {

		.approach {
			background-position: 65% 100%;
		}
	}
}

@media screen and (max-width: 1216px) {

	.home_container {

		.sessions {

			.session_info {

				.session_features {
					margin-top: 2em;
					margin-left: 0;
				}
			}
		}
	}
}

@media screen and (min-width: 770px) and (max-width: 830px) {

	.home_container {

		.home_banner {

			.features {
				margin-top: 15%;
			}
		}
	}
}

@media screen and (max-width: 850px) {

	.home_container {

		h2 {
			font-size: 3rem;
		}

		.home_banner {

			.home_image {
				height: 75vh;
			}

			.features {
				margin-top: 5rem;
			}
		}
	}
}

@media screen and (max-width: 800px) {

	#reviews_caro {

		.reviews_wrapper {

			.carousel_inner {

				.reviews_container {
					padding-inline: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 750px) {

	.home_container {

		.home_banner {

			.home_image {

				h2 {
					font-size: 2.5rem;
					padding: 25px 15px;
				}

				.btn_ctn {

					a {
						font-size: 1.3rem;
						padding: 20px 25px;
					}
				}
			}
		}

		.sessions {
			padding-inline: 20px;

			h2 {
				font-size: 2.7rem;
			}

			.session_info {

				.session_content {
					margin-inline: 1em;
					padding: 2.5em 1em;
				}

				.session_features {
					padding-top: 2em;
					margin: 0;

					.item_wrap {
						padding-left: 2em;

						.item_text_wrap {
							font-size: .9rem;
						}
					}
				}
			}
		}

		.approach {

			.approach_container {

				h2 {
					font-size: 2.7rem;
				}

				.approach_info {
					padding: 2em 2.5em 1em 2.5em;
				}
			}
		}

		.about_us {

			.employees {

				.owner_about {
					display: flex;
					flex-direction: column;

					h3 {
						text-align: center;
					}
				}
			}
		}

		.session_plans {
			padding: 2em 3em;

			h2 {
				font-size: 2.7rem;
			}

			.plan_items {
				padding: 4em;
			}
		}

		.worked_with {
			padding-block: 2em;

			h2 {
				font-size: 2.7rem;
			}
		}
	}

	#reviews_caro {
		display: none;
	}
}

@media screen and (min-width: 730px) and (max-width: 750px) {

	.home_container {

		.home_banner {

			.features {
				margin-top: 12%;
			}
		}
	}
}

@media screen and (min-width: 650px) and (max-width: 720px) {

	.home_container {

		.home_banner {

			.features {
				margin-top: 18%;
			}
		}
	}
}

@media screen and (max-width: 640px) {

	.home_container {

		.home_banner {

			.features {
				margin-top: 20%;
			}
		}
	}
}

@media screen and (max-width: 500px) {

	.home_container {

		.home_banner {

			.home_image {

				h2 {
					padding: 0 15px;
				}
			}
		}

		.sessions {
			padding-inline: 20px;

			.session_info {

				.session_content {
					margin-inline: 1em;
				}

				.session_features {
					padding-top: 2em;
					margin: 0;

					.item_wrap {
						padding-left: 2em;

						.item_text_wrap {
							font-size: 1rem;
						}
					}
				}
			}
		}

		.approach {

			.approach_container {

				.approach_info {
					padding: 2em 2.5em 1em 2.5em;
				}
			}
		}

		.about_us {

			.employees {

				.owner_about {
					display: flex;
					flex-direction: column;

					h3 {
						text-align: center;
					}
				}
			}
		}

		.session_plans {
			padding: 2em 1em;

			h2 {
				font-size: 2.3rem;
			}
		}

		.worked_with {

			h2 {
				font-size: 2rem;
			}

			.companies {
				padding: 0;
			}
		}
	}

	#reviews_caro {
		display: none;
	}
}