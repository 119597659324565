#footer {
	margin-top: 20px;
}

#footer_top_border {
	padding: 10px;
	background: linear-gradient(90deg, #fff, $smoke, #fff);
}

#footer_top {
	width: 80%;
	margin: 0 auto;
	padding: 35px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.footer_logo {
		width: 500px;
	}
}

#footer_nav {
	flex: 1;
	display: flex;
	justify-content: space-evenly;
	color: $primary;
	font-weight: 700;
	text-decoration: none;

	a {
		color: inherit;
		text-decoration: none;

		&:hover {
			color: $secondary;
		}
	}

	li {

		li {
			margin-bottom: 10px;
		}
	}

	.footer_nav_column {

		.text_underline {
			text-decoration: underline;
			text-decoration-color: $smoke;
			text-decoration-thickness: 2px;
			text-underline-offset: 5px;
		}
	}

	.footer_sub_menu {
		margin: 15px 0 0 25px;

		li {
			position: relative;
			margin-bottom: 10px;

			&::before {
				position: absolute;
				content: "";
				width: 10px;
				height: 10px;
				right: calc(100% + 5px);
				bottom: 50%;
				background: $accent;
				transform: rotate(45deg) translateY(50%);
			}
		}
	}
}

#footer_bottom {
	padding: 35px 0;
	background: linear-gradient(90deg, #fff, $smoke, #fff);
	text-align: center;

	a {
		color: $dark_gray;
		text-decoration: none;
	}
}

#legality_links {
	margin-bottom: 20px;
	display: flex;
	justify-content: center;

	li {
		opacity: .4;

		&+ li {
			margin-left: 10px;
			padding-left: 10px;
			border-left: 1px solid $dark_gray;
		}
	}

	a {
		color: $dark_gray;


		&:hover {
			text-decoration: underline;
		}
	}
}

#footer_credit {

	div {
		opacity: .4;
	}

	img {
		width: 130px;
		margin-top: 10px;
		opacity: .4;
	}
}

/***>>Media Queries***/
@media screen and (max-width: 1500px) {

	#footer_top {
		width: 100%;
		padding: 35px;
		flex-wrap: wrap;
		justify-content: center;
		box-sizing: border-box;
	}

	#footer_nav {
		margin-top: 20px;
		flex: 0 0 100%;
	}
}

@media screen and (max-width: 750px) {

	#footer_top {

		.footer_logo {
			width: 100%;
		}
	}

	#footer_nav {
		display: none;
	}

	#legality_links {
		display: none;
	}
}