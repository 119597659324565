#internal_header {

	&.contact_us {
		background: url('/dist/images/backgrounds/img_connectwithus.jpg');
		background-repeat: no-repeat;
		width: 100%;
		object-fit: cover;
	}
}

#contact {
	color: $primary;
	text-align: center;

	.secondary_banner {
		background: url("/dist/images/content/img_tree_bg.jpg");
		height: 350px;
		display: flex;
		justify-content: space-evenly;
		background-position: center center;
		flex-direction: column;

		h2 {
			font-family: $font_secondary;
			font-weight: 400;
			font-size: 1.7rem;
			color: #fff;
			margin-top: 5px;
			padding-inline: 1em;
			text-decoration: underline;
			text-decoration-color: $accent;
			text-decoration-thickness: 1px;
			text-underline-offset: 15px;
		}

		.inner-container {
			background: #fff;
			height: 155px;
			border-radius: 10px;
			margin: 0 85px 25px 85px;
			box-shadow: 3px 3px 10px #00000085;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: center;
			padding: 0 50px;

			.company_logo {
				background: url("/dist/images/logos/logo_rtblc.svg");
				height: 75px;
				width: 390px;
				background-repeat: no-repeat;
				text-align: center;
				margin-left: 10px;
			}

			a {
				background: $primary;
				text-decoration: none;
				font-size: 14px;
				color: #fff;
				padding: 18px 72px 18px 35px;
				border-radius: 10px;
				display: flex;
				align-items: center;

				img {
					margin-right: 20px;
				}

				&:hover {
					box-shadow: 2px 2px 8px black;
				}
			}
		}
	}

	.internal_content {
		text-align: center;
		padding: 43px 0;

		.contact_title {
			padding: 0 35px;
			font-size: 1.6rem;
			line-height: .65;
			font-weight: 600;
			padding-bottom: 45px;

			p {
				line-height: 1.2;
			}

			span {
				font-size: 1.29rem;
				color: $secondary;
			}
		}
	}
}

#contact_form {

	.form_inputs {
		background: linear-gradient(180deg, #f2f2f2 0%, #ffffff 100%);
		padding: 55px 50px 20px 50px;
		margin: 0 85px;
		display: grid;
		gap: 15px;

		.response_message {

			span {

				&.error {
					color: $secondary;
				}

				&.success {
					color: #090;
				}
			}
		}

		label {
			display: none;
		}

		input,
		textarea {
			color: $primary;
			font-size: 1rem;
			height: 40px;
			border: 2px solid $smoke;
			padding: 0 24px;
			max-width: 745px;

			&::placeholder {
				color: $primary;
			}

			&.error {
				border: 1px solid $secondary;
			}
		}

		textarea {
			font-family: inherit;
			min-height: 150px;
			padding-top: 22px;
			outline: none;
			margin-bottom: 10px;
		}
	}

	button {
		font-size: 1.2rem;
		width: 260px;
		height: 62px;
		border: none;
	}
}

#hp_wrap {
	position: fixed;
	top: -999px;
	opacity: 0;
}

/***>>>Media Queries***/
@media screen and (max-width: 1040px) {

	#contact {

		.contact_title {
			line-height: 1;
		}

		.secondary_banner {
			line-height: 1.8;

			.inner-container {
				justify-content: center;
				padding: 10px 10px;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#contact {

		.secondary_banner {

			.inner-container {
				margin: 0;
				border-radius: 0;
			}
		}
	}

	#contact_form {

		.form_inputs {
			margin: 0;
		}
	}
}