/*********************
* Fonts
*********************/
@font-face {
	font-family: 'Dancing Script';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/dancingscript/v19/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup8.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: 'Enriqueta';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/enriqueta/v10/goksH6L7AUFrRvV44HVjTEqi.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v27/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v27/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
$font_heading: 'Dancing Script', sans-serif;
$font_primary: 'Open Sans', sans-serif;
$font_secondary: 'Enriqueta', sans-serif;
/*********************
* Colors
*********************/
$primary: #1f4387;
$secondary: #a2214e;
$secondary_alt: #721036;
$accent: #bbd09a;
$smoke: #f2f2f2;
$gray: #e6e6e6;
$dark_gray: #333333;

/*********************
* Reusable Styles
*********************/
/*** ID ***/

#skip_nav {
	position: fixed;
	top: -9999px;
	left: -9999px;
}

#container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	min-height: 100vh;
	margin: 0 auto;
	padding: 0;
	overflow: hidden;
	min-width: 360px;
	max-width: 1920px;
	font-family: $font_primary;
	font-size: 16px;
}

/*** Classes ***/

.btn {
	display: inline-block;
	background: $secondary;
	font-family: $font_primary;
	font-size: 16px;
	border-top-left-radius: 20px;
	border-bottom-right-radius: 20px;
	color: $smoke;
	text-decoration: none;
	transition: .3s background;
	box-shadow: 0 4px 4px rgba(#000, .40);
	padding: 12px 50px 12px 30px;
	position: relative;

	&:after {
		content: "";
		position: absolute;
		color: #fff;
		top: 50%;
		right: 30px;
		height: 8px;
		width: 8px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		transform: rotate(45deg) translateY(-50%);
	}

	&:hover {
		background: $secondary_alt;
	}
}