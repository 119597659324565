#header_inner {
	position: relative;
	max-width: 95%;
	margin: 0 auto;
	padding: 20px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

#header_left {
	max-width: 550px;
	width: 100%;
}

#nav {
	display: flex;
	align-items: center;

	li {

		&+li {
			margin-left: 20px;
		}
	}

	a {
		color: $primary;
		text-decoration: none;
		font-weight: 700;

		&.active {
			text-decoration: underline;
			text-decoration-color: $smoke;
			text-decoration-thickness: 2px;
			text-underline-offset: 5px;
		}

		&:hover {
			color: $secondary;
		}
	}

	.has_sub_menu {
		position: relative;

		span {
			position: relative;
			color: $primary;
			font-weight: 700;
			cursor: pointer;
			z-index: 2;

			&:hover {
				color: $secondary;
			}
		}

		.sub_menu {
			display: none;
			position: absolute;
			width: 300px;
			top: 0;
			right: 50%;
			padding-top: 50px;
			transform: translateX(50%);
			box-sizing: content-box;
			z-index: 1;

			ul {
				background: $primary;
				position: relative;
				padding: 0;

				li {
					position: relative;
					margin: 0;
					padding: 20px 40px;

					&+li {

						&:after {
							position: absolute;
							content: "";
							left: 40px;
							top: 0;
							width: 225px;
							height: 1px;
							background: $smoke;
						}
					}
				}

				a {
					position: relative;
					display: block;
					color: $smoke;
					font-weight: 500;

					&:hover {
						color: #fff;
					}
				}
			}


			&::after {
				position: absolute;
				content: "";
				left: 50%;
				top: 45px;
				width: 30px;
				height: 30px;
				background-color: $primary;
				transform: rotate(45deg) translateX(-50%);
			}
		}

		&:hover {

			.sub_menu {
				display: block;

				li {
					z-index: 1;
				}
			}
		}
	}

	.btn {
		padding: 15px 30px;
		color: #fff;
		font-weight: 500;

		&:after {
			content: none;
		}

		&:hover {
			color: #fff;
		}
	}
}

#header_right {

	.mobile_nav {
		display: none;

		li {
			margin-top: 10px;
			padding: 10px;
			text-align: right;

			&.mobile_pay_link {
				background: $secondary;
			}
		}

		a {
			color: #fff;
			text-decoration: none;
		}
	}
}

#toggle_mobile_nav {
	display: block;
	position: absolute;
	height: 40px;
	width: 40px;
	padding: 0;
	margin: 0;
	background: $primary;
	top: 50%;
	right: 5px;
	transform: translateY(-50%);
	border: none;

	&::before, &::after {
		height: 8px;
		width: 100%;
		background: #fff;
		margin: 8px 0;
		content: "";
		display: block;
		transition: transform .3s;
	}

	&[state="open"] {

		&::before {
			transform: translateY(8px) rotate(45deg);
		}

		&::after {
			transform: translateY(-8px) rotate(-45deg);
		}
	}

	@media screen and (min-width: 751px) {
		display: none;
	}
}

/***>>Media Queries***/
@media screen and (max-width: 1700px) {

	#header_inner {
		max-width: 100%;
		padding: 1em;
	}

	#header_left {
		max-width: 500px;
		margin-right: 10px;
	}
}

@media screen and (max-width: 1600px) {

	#nav {

		li {

			&+li {
				margin-left: 10px;
			}
		}
	}
}

@media screen and (max-width: 1500px) {

	#header_inner {
		flex-direction: column;
	}

	#nav {
		margin-top: 10px;
		flex-wrap: wrap;
		justify-content: center;

		.has_pay_link {
			flex: 0 0 100%;
			margin: 15px 0 0;
			text-align: center;
		}
	}
}

@media screen and (max-width: 950px) {

	#nav .has_sub_menu .sub_menu {
		right: 0;
		transform: none;

		&:after {
			left: calc(100% - 26px);
		}
	}
}

@media screen and (max-width: 750px) {

	#header_inner {
		flex-direction: row;
		padding: 10px 45px 10px 10px;
	}

	#nav {
		display: none;
	}

	#header_right {
		position: absolute;
		top: 80px;
		right: 0;
		width: 250px;
		height: 150vh;
		background: $primary;
		transform: scaleX(0);
		transform-origin: right;
		transition: transform .3s;
		z-index: 999;

		&[state="open"] {

			transform: scaleX(1);

			.mobile_nav {
				display: block;
			}
		}
	}
}