#internal_header {
	background-image: url('/dist/images/backgrounds/img_community.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	padding: 120px 0 145px 0;
	margin: 0 auto;
	color: $smoke;
	font-family: $font_heading;
	font-weight: 700;
	font-size: 60px;
	text-align: center;
}

.internal_content_wrapper {
	padding: 0 35px;
}

.internal_content_container {
	max-width: 1054px;
	width: 100%;
	margin: 0 auto;
	background: $smoke;
	border-radius: 15px;
	margin-top: -60px;
	padding: 20px;
	box-sizing: border-box;
}

.internal_content {
	background: #ffffff;
	border-radius: 15px;
	padding: 45px 35px;
	box-shadow: 0px 4px 8px rgba(#000, .25);

	&.error {
		min-height: 400px;
		text-align: center;

		p {
			font-size: 18px;
			font-weight: 700;
			line-height: 1.8;

			a {
				text-decoration: none;
				color: $secondary;
				transition: .3s color;
				font-weight: 700;

				&:hover {
					color: $secondary_alt;
				}
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#internal_header {
		font-size: 40px;
		padding: 60px 0;
	}

	.internal_content_wrapper {
		padding: 0;
	}

	.internal_content_container {
		max-width: 100%;
		border-radius: 0;
		margin-top: 0;
		padding: 20px 0;
		box-sizing: border-box;
	}

	.internal_content {
		border-radius: 0;
		box-shadow: none;
	}
}

@media screen and (max-width: 500px) {

	#internal_header {
		font-size: 30px;
		padding: 30px 0;
	}

	.internal_content {
		padding: 45px 15px;
	}
}