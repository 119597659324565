#legality {

	h3 {
		font-size: 1.5rem;
		font-weight: 700;
		color: $primary;
		margin: 0 0 5px;
		line-height: 1.2;
	}

	p {
		margin: 0 0 1rem;
		line-height: 1.4;
	}

	a {
		text-decoration: none;
		color: $secondary;
		transition: color .3s;

		&:hover {
			color: $dark_gray;
		}
	}

	ul {
		list-style-type: disc;
		margin: 0 0 20px;

		li {
			margin: 0 0 0 40px;
			line-height: 1.4;
		}
	}
}

@media screen and (max-width: 500px) {

	#legality {

		a {
			word-break: break-all;
		}
	}
}
