.internal_content {

	&.plans_and_pricing {

		font-family: $font_secondary;
		padding: 45px 0;

		p {
			line-height: 1.2;
			font-weight: 500;
		}

		.phone_link {
			font-family: $font_primary;
		}

		span {

			&.error, &.success {
				font-family: $font_primary;
				font-size: 16px;
			}
		}

		#contact_form {

			.form_inputs {

				textarea {
					font-family: $font_primary;
				}
			}
		}
	}
}


#internal_header {

	&.plans_and_pricing_header {
		background-image: url('/dist/images/backgrounds/img_plansandpricing.jpg');
	}
}

.plans_and_pricing_inner {
	padding: 22px 88px 0 88px;
	border-radius: 15px 15px 0 0;

	.inner_gradiant_bgcolor_container {
		padding: 0 108px 0 96px;
		background: linear-gradient(to bottom, #ffffff 0%, #f2f2f2 100%);
		margin-bottom: 8%;

		p {
			margin-top: 1.75rem;
			font-size: 1rem;
			font-family: $font_primary;
		}

		h3 {
			font-size: 1.75rem;
			font-weight: 400;
			color: $primary;
			padding-top: 15px;
		}

		hr {
			margin-left: 0;
			max-width: 395px;
			width: 100%;
			height: 3px;
			background-color: lightgray;
			border: none;
		}

		.page_icon_desktop {
			background-repeat: no-repeat;
			margin-left: 4%;
			margin-top: 8%;
		}
	}

	.package_price_dropdown_container {

		background: linear-gradient(to top, #ffffff 50%, #f2f2f2 100%);
		width: 100%;

		.package_price_dropdown {
			background-color: #1f4387;
			border-radius: 10px;
			color: white;
			vertical-align: middle;
			text-align: center;
			line-height: 55px;
			padding: 27px;
			position: relative;
			margin-bottom: 5px;
			cursor: pointer;

			span {
				font-family: $font_secondary;
				margin: 0 auto;
				font-size: 2rem;
			}

			.intro_rate {
				line-height: 0px;
				font-size: 1.188rem;
			}

			.intro_rate2 {
				line-height: 0px;
				font-size: 1.188rem;
			}

			&:after {
				display: block;
				content: "";
				position: absolute;
				color: #fff;
				top: 50%;
				right: 40px;
				height: 19px;
				width: 19px;
				border-top: 5px solid #fff;
				border-right: 5px solid #fff;
				transform: translateY(-50%) rotate(45deg);
				cursor: pointer;
				transition: transform .3s;
				transform-origin: center 25%;
			}
		}

		&.open .package_price_dropdown:after {
			transform: translateY(-50%) rotate(135deg);
		}

		.inner_text_wrapper {
			width: 90%;
			border: thin solid lightgray;
			border-radius: 62px 0 62px 0;
			margin: 0 auto;
			margin-top: 40px;
			background-color: white;
			box-shadow: 12px 12px #e3e3e3;
			text-align: center;
			padding-bottom: 3em;

			.inner_text {
				text-align: center;
				padding: 0 10px;

				.title {
					font-size: 1.5rem;
					font-weight: 700;
					color: $primary;
					margin-bottom: 20px;
					margin-top: 35px;
				}

				.price {
					font-size: 1.75rem;
					font-weight: 700;
					font-family: $font_primary;
					color: $primary;
					margin-top: 0;
					margin-bottom: 5px;
				}

				.price2 {
					font-size: 1.25rem;
					font-weight: 700;
					font-family: "Open Sans", sans-serif;
					color: #1f4387;
					margin: 0 78px 5px 62px;
				}

				.line_one {
					margin-bottom: 10px;
				}

				.line_three {
					margin: 0 0 50px 0;
				}
			}

			.btn {
				border: none;
				padding: 16px 63px;
				border-top-left-radius: 25px;
				border-bottom-right-radius: 25px;

				a:link {
					text-decoration: none;
					color: white;
				}
			}

			hr {
				width: 48%;
				height: 3px;
				background-color: lightgray;
				border: none;
			}

			.hr1 {
				width: 87%;
				height: 3px;
			}

			#intro_price {
				font-size: 1.125rem;
				font-weight: 300;
				padding: 0;
			}
		}


		.package_price_content {
			display: none;
		}

		&.open .package_price_content {
			display: block;
		}

		#individual_content {
			margin-bottom: 109px;
		}

		#three_session_pkgB {
			margin-bottom: 109px;
		}

		#intro_price {
			font-size: 1.125rem;
		}

		#dropdown_arrow2 {
			padding-bottom: 0;
		}
	}

	#package_price_dropdown {
		height: 110px;
		line-height: 20px;
	}

	//Bullet point list

	.inner_list_wrapper {
		background: linear-gradient(to bottom, #ffffff 0%, #f2f2f2 100%);
		width: 100%;
		margin-bottom: 0;
		margin-top: 2em;

		ul {

			padding: 73px;
			font-size: 1.375rem;
			font-weight: 700;
			color: $primary;
			list-style: none;

			li {
				margin-bottom: .5em;
				padding-left: 2em;
				position: relative;
				line-height: 25px;
				font-family: $font-primary;
				font-weight: bold;

				&:after {
					content: '';
					height: .6em;
					width: .6em;
					background: #bcd198;
					display: block;
					position: absolute;
					transform: rotate(45deg);
					top: .25em;
					left: 0;
				}
			}
		}
	}
}


@media screen and (max-width: 1400px) {

	#internal_header {

		&.plans_and_pricing_header {
			background-position: center center;
		}
	}
}

@media screen and (max-width: 1100px) {

	.plans_and_pricing_inner {

		.internal_content {

			.plans_and_pricing_inner {
				padding: 22px 35px 0 35px;
			}
		}

		.inner_gradiant_bgcolor_container {
			padding: 0 40px;
		}

		.package_price_dropdown_container {

			.package_price_dropdown {
				padding: 24px 42px;
				line-height: 1.4;

				span {
					font-size: 1.75rem;
				}

				.intro_rate, .intro_rate2 {
					line-height: 1;
				}
			}
		}
	}
}

@media screen and (max-width: 850px) {

	.plans_and_pricing_inner {

		.package_price_dropdown_container {

			.package_price_dropdown {

				span {
					font-size: 1.30rem;
				}
			}
		}
	}
}

@media screen and (max-width: 750px) {

	.internal_content {

		.plans_and_pricing_inner {
			padding: 22px 0 0 0;
		}
	}

	.plans_and_pricing_inner {

		.package_price_dropdown_container {

			.package_price_dropdown {
				border-radius: 0;
				padding: 24px 70px;
			}

			#three_session_pkgB {
				margin-bottom: 60px;
			}
		}

		.inner_list_wrapper {

			ul {
				padding: 35px;

				li {
					font-size: 16px;

					&:after {
						top: .5em;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {

	.plans_and_pricing_inner {

		.inner_gradiant_bgcolor_container {

			h3 {
				font-size: 1.25rem;
			}
		}

		.package_price_dropdown_container {

			.package_price_dropdown {

				span {
					font-size: 1.17rem;
				}
			}

			.inner_text_wrapper {
				padding-bottom: 2em;

				.inner_text {

					.price2 {
						margin: 0 20px 5px 20px;
					}

					.line_three {
						margin: 0 0 25px 0;
					}
				}
			}
		}
	}
}